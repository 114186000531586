.downloader {
    width: 500px;
    min-height: 128px !important;
    position: fixed;
    right: 70px;
    bottom: 10px;
    max-height: 300px !important;
    overflow-y: auto;
    z-index: 999
}

.downloader .card-header {
    color: #fff;
    background-color: #2F80ED;
    padding: 15px !important;
}

.downloader .card .list-group {
    max-height: 300px !important;
    overflow: hidden;
    overflow-y: auto;
}