
// @import url('magnific-popup.css') !important;
// @import url('owl.carousel.min.css') !important;

// from designer
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap');

html{
  -webkit-font-smoothing: antialiased;
}

#main_page_design{
  font-family: 'Montserrat', sans-serif !important;
      font-size: 1.25em !important;
      line-height: 1.625em !important;
      color: #767676 !important;
      overflow-y: auto !important;
      overflow-x: hidden !important;
}

::-webkit-scrollbar{
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: #babfda;
}

::selection{
  background-color:#babfda;
  color:#000;
}

-webkit-::selection{
    background-color:#babfda;
    color:#000;
}

::-moz-selection{
    background-color:#babfda;
    color:#000;
}


#main_page_design {

h1,
.h1{
  position: relative !important;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 5.725rem !important;
  font-weight: 700 !important;
  line-height: 1 !important;
  color: #000 !important;
}

 h2,
 .h2{
  position: relative !important;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 700 !important;
  font-size: 2.9rem !important;
  line-height: 1.2 !important;
  color: #000 !important;
  margin:2rem 0 !important;
}


h3,
.h3{
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 600 !important;
    font-size: 2rem !important;
  line-height: 1.25 !important;
  color: #000 !important;
  margin:1.5rem 0 !important;
}

h4,
.h4{
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 600 !important;
  font-size: 1.89rem !important;
  line-height: 1.2 !important;
  color: #000 !important;
  margin:1.5rem 0 !important;
}

h5,
.h5{
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 600 !important;
  font-size:1.4rem !important;
  line-height: 1.2 !important;
  color: #000 !important;
  margin:1.2rem 0 !important;
}

h6,
.h6{
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 700 !important;
  font-size: 0.9rem !important;
  line-height: 1.2 !important;
  color: #000 !important;
  margin:1rem 0 !important;
  text-transform: uppercase !important;
}


h1 a,
.h1 a,
h2 a,
.h2 a,
h3 a,
.h3 a,
h4 a,
.h4 a,
h5 a,
.h5 a,
h6 a,
.h6 a{
  color:inherit !important;
}

h1 a:hover,
.h1 a:hover,
h2 a:hover,
.h2 a:hover,
h3 a:hover,
.h3 a:hover,
h4 a:hover,
.h4 a:hover,
h5 a:hover,
.h5 a:hover,
h6 a:hover,
.h6 a:hover{
  text-decoration: none !important;
}

p{
  margin-bottom: 1rem !important;
}

ul{
  list-style: none !important;
  padding-left: 0 !important;
}

a {
  color:#0045a1 !important;
  -webkit-transition: color .3s ease-out !important;
       -o-transition: color .3s ease-out !important;
          transition: color .3s ease-out !important;
}

a:hover,
a:focus {
  color:#0045a1 !important;
  outline: none !important;
}

a:focus{
  text-decoration: none !important;
}

.lead{
  line-height: 1.9 !important;
  font-size: 1.125rem !important;
}

.size-sm{
  font-size: 0.875rem !important;
}

.form-group{
  margin-bottom: 2rem !important;
}

.form-control,
textarea.form-control{
  font-size: 0.8em !important;
  height: 3.625em !important;
  border-radius: 0 !important;
  padding-left: 22px !important;
  color: #000 !important;
  background-color: #0000001a !important;
  border:0 !important;
  border-bottom:1px solid rgba(255,255,255,0.6) !important;
  -webkit-box-shadow:none !important;
          box-shadow:none !important;
      -webkit-appearance: none !important;
  -webkit-transition: all .15s !important;
       -o-transition: all .15s !important;
          transition: all .15s !important;
}

textarea.form-control{
  height: 7.9em !important;
  padding-top:1.1em !important;
  resize:none !important;
}

.form-control:focus{
  border-color:#cecece !important;
  outline: 0 !important;
  -webkit-box-shadow:none !important;
          box-shadow:none !important;
          background-color: none !important;
          background-color: #00000024 !important;
}

.form-control::-moz-placeholder {
  color: #bbbbbb !important;
  opacity: 1 !important;
}

.form-control:-ms-input-placeholder {
  color: #bbbbbb !important;
}

.form-control::-webkit-input-placeholder {
  color: #bbbbbb !important;
}

label.error{
  border-color:#FFC700 !important;
  padding:9px 0 0 0px !important;
  font-size:0.9rem !important;
  color: red !important;
}

.mc-label{
  padding:9px 0 0 22px !important;
  font-size:0.9rem !important;
  color: #fff !important;
}
p.creator_list {
    font-size: 11px !important;
    text-align: center !important;
    line-height: 14px !important;
}
.position-absolute {
  background-size: cover !important;
}

.btn{
  font-family: 'Montserrat', sans-serif !important;
  position: relative !important;
  display: inline-block !important;
  border:0.2em solid #FFC700 !important;
  border-radius: 0 !important;
  padding:1em 2.4em 1em !important;
  font-size: 0.875em !important;
  color:#000 !important;
  white-space: nowrap !important;
  text-align: center !important;
  text-transform: uppercase !important;
  text-decoration: none !important;
  background-color:#FFC700 !important;
  font-weight: 700 !important;
  border-width: 1px !important;
  -webkit-transition: all .3s ease-out !important;
          transition: all .3s ease-out !important;
          height: 50px;
    min-width: 135px;
}

.btn:hover,
.btn:focus,
.btn:active{
  color: #000 !important;
  background-color: #ecff73 !important;
  border-color:#ecff73 !important;
  -webkit-box-shadow:none !important;
  box-shadow: none !important;
  outline: none !important;
}

.btn-white{
  background-color: #fff !important;
  border-color: #fff !important;
  color: #343434 !important;
}

.btn-white:hover{
  background: transparent !important;
  border-color: #fff !important;
  color: #fff !important;
}

.btn-b-white{
  background-color: transparent !important;
  border-color: #fff !important;
}

.btn-b-white:hover{
  background-color: #fff !important;
  color: #000 !important;
}

.btn-bg-dark:hover,
.btn-bg-dark:focus{
  color: #fff !important;
  border-color:#fff !important;
}

ion-icon{
  color:#23a592 !important;
  font-size: 3.8rem !important;
  --ionicon-stroke-width: 17px !important;

}

.icon-play{
  position: absolute !important;
  width:5.15rem !important;
  height: 5.15rem !important;
  left: 50% !important;
  top:50% !important;
  transform:translate(-50%,-50%) !important;
  border:1px solid #fff !important;
  border-radius: 50% !important;
  transition:all 0.3s ease !important;
}

.icon-play:before{
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  margin:-6px 0 0 -5px !important;
  content: '' !important;
  width: 0 !important;
  height: 0 !important;
  border-style: solid !important;
  border-width: 6px 0 6px 12px !important;
  border-color: transparent transparent transparent #fff !important;
}

.icon-play:hover{
  border:1px solid #FFC700 !important;
  background-color:#FFC700 !important;
  color: #343434 !important;
}

.icon-play:hover:before{
  border-color: transparent transparent transparent #000 !important;
}

.loader{
  position:absolute !important;
  z-index: 10 !important;
  z-index: 100 !important;
  left: 0 !important;
  width: 100% !important;
  top:0 !important;
  height: 100% !important;
  background: #fff !important;
}

.spinner {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  width: 50px !important;
  height: 50px !important;
  -webkit-transform: translate(-50%, -50%) !important;
  -moz-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  -o-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}
.double-bounce1, .double-bounce2 {
  width: 100% !important;
  height: 100% !important;
  border-radius: 50% !important;
  background-color: #FFC700 !important;
  opacity: 0.6 !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out !important;
  animation: sk-bounce 2.0s infinite ease-in-out !important;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s !important;
  animation-delay: -1.0s !important;
}
@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0.0) !important;
    -webkit-transform: scale(0.0) !important;
  } 50% {
    transform: scale(1.0) !important;
    -webkit-transform: scale(1.0) !important;
  }
}

.bg-light{
  background: #f5f5f5 !important;
}

.bg-dark{
  background:#1d1d1d !important;
  color:#86878a !important;
}

.bg-dark .row-experience:nth-child(even){
  background-color:#272727 !important;
}

.bg-dark .btn:hover,
.bg-dark .btn:focus{
  color: #fff
}

.bg-primary{
  background:#23a592 !important;
  color:#fff !important;
}
 .text-secondary {
  color: #2F80ED  !important;
}
.bg-secondary {
  background: #2F80ED   !important;
  border-color: #2F80ED  !important;
  color: #fff  !important;
}
.bg-dark .progress{
  background: #383838 !important;
}
.text-normal{
  line-height: 20px !important;
}

.text-line.sub_text {
    font-size: 1.1rem !important;
    line-height: 22px !important;
    font-weight: 400 !important;
}



.text-dark{
  color:#000 !important;
}

.text-white{
  color:#fff !important;
}

.text-gray{
  color:#767676 !important;
}

.text-muted{
  color: #bbbbbb !important;
}

 .text-primary{
  color:#FFC700 !important;
}

}

#main_page_design {

.toggler{
 position: absolute !important;
 right: 30px !important;
 width:2rem !important;
 height: 2rem !important;
 padding:0 !important;
 background: none !important;
 border:0 !important;
}

.toggler:focus{
  outline: none !important;
}

.toggler-icon{
  display: block !important;
  border-top:2px solid #fff !important;
  margin-top: 0.5rem !important;
}

.toggler-icon:first-child{
  margin-top: 0 !important;
}


.navbar-brand:hover,
.navbar-brand:focus{
  color:#fff !important;
}
}

#main_page_design {

.navbar-desctop .phone,
.navbar-desctop .email{
  float: left !important;
  font-family: 'Montserrat', sans-serif !important;
  position: relative !important;
  top:0.3rem !important;
  margin-right: 4rem !important;
}

.navbar-bottom{
  position: absolute !important;
  left: 0 !important;
  width: 100% !important;
  bottom:0 !important;
  padding:0 1.5rem 3rem 1.5rem !important;
}
}

@media (min-width: 992px){
  .navbar-bottom{
    padding-left: 3rem !important;
    padding-right:3rem !important;
  }
}
#main_page_design {
.navbar-bottom{
  font-family: 'Montserrat', sans-serif !important;
  color: #000 !important;
}

.navbar-bottom .social{
  position: relative !important;
  z-index: 10 !important;
  float: left !important;
}

.navbar-bottom .copy{
  float: right !important;
  position: relative !important;
  z-index: 10 !important;
  color: #fff !important;
}

.social-icons li{
  float: left !important;
  margin-right: 1.5rem !important;
}

.social-icons ion-icon{
  display: block !important;
  font-size:1.2rem !important;
  color:#fff !important;
  transition: all 0.3s ease !important;
}

.social-icons ion-icon:hover{
  color: #FFC700 !important;
}

.navbar-bottom-white,
.navbar-bottom-white .social-icons ion-icon{
  color: #fff !important;
}


.navbar-desctop:before{
  content:'' !important;
  position: absolute !important;
  top:0 !important;
  left:0 !important;
  width: 100% !important;
  height: 0 !important;
  background:#fff !important;
  transition: all 0.3s ease !important;
}

.navbar-nav-desctop{
  font-family: 'Montserrat', sans-serif !important;
  font-size:0.8rem !important;
  text-transform: uppercase !important;
  font-weight: 400 !important;
  margin-top:0.3rem !important;
}

.nav-link{
  padding: 0 !important;
}

.navbar-nav-desctop li a{
  color: #343434 !important;
  transition:all 0.3s !important;
}

.navbar-nav-desctop li a:hover{
  text-decoration: none !important;
}

.navbar-nav-desctop > li{
  position: relative !important;
  float: left !important;
  margin-right: 2.1rem !important;
}

.navbar-nav-desctop > li > a{
  display: flex !important;
  align-items: center !important;
}

.navbar-nav-desctop > li > .active,
.navbar-nav-desctop >  li:hover > a{
  color:#767676 !important;
}

.navbar-nav-desctop li ul{
  opacity: 0 !important;
  visibility: hidden !important;
  padding:0.7rem 1.8rem !important;
  position: absolute !important;
  top:85% !important;
  background:#1a1c26 !important;
  color:#000 !important;
  min-width: 12rem !important;
  margin-left: -1.5rem !important;
  transition:all 0.3s linear !important;
}

.navbar-nav-desctop li:hover ul{
  top:100% !important;
  opacity: 1 !important;
  visibility: visible !important;
}

.navbar-nav-desctop li ul li{
  margin:1rem 0 !important;
}

.navbar-nav-desctop li ul li a{
  opacity: 0.5 !important;
  display: block !important;
}

.navbar-nav-desctop li ul .active a,
.navbar-nav-desctop li ul li:hover a{
  opacity: 1 !important;
}

.click-capture{
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  visibility: hidden !important;
  opacity: 0 !important;
  z-index: 99 !important;
  background: rgba(0,0,0,0.3) !important;
  transition: opacity 0.5s !important;
}

.navbar-mobile{
  display: block !important;
  align-items: baseline !important;
  flex-wrap: unset !important;
  padding:3.9rem 2.6rem 1.3rem 4.4rem !important;
  position: fixed !important;
  top:0 !important;
  bottom:0 !important;
  right: 0 !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 110 !important;
  background-color: #1b001d !important;
  overflow-x: auto !important;
  -webkit-overflow-scrolling: touch !important;
  display: -ms-flexbox !important;
  box-shadow:0 0 50px rgba(0,0,0,0.1) !important;
  -webkit-transform: translateX(100%) !important;
  -ms-transform: translateX(100%) !important;
  -o-transform: translateX(100%) !important;
  transform: translateX(100%) !important;
  display: flex !important;
  flex-direction: column !important;
  -webkit-transition: all 0.6s ease !important;
  -o-transition: all 0.6s ease !important;
  transition: all 0.6s ease !important;
}

.navbar-mobile .language{
  margin-top:0 !important;
  font-size: 0.85rem !important;
}

.navbar-mobile .language li{
  float: left !important;
  color: #fff !important;
  margin-right: 1rem !important;
}

.navbar-mobile .language li a{
  color: #fff !important;
}

.navbar-mobile .language .active a,
.navbar-mobile .language li a:hover{
  color: #FFC700 !important;
  text-decoration: none !important;
}

.navbar-mobile .close{
  position: absolute !important;
  right: 1rem !important;
  top:0.5rem !important;
  font-size: 3rem !important;
  color: #fff !important;
  transition:all 0.3s ease !important;
  cursor: pointer !important;
  opacity: 1 !important;
}

.navbar-mobile .close:hover{
  opacity: 1 !important;
  color:#FFC700 !important;
}
}
#main_page_design {
.navbar-mobile-footer{
  margin-top: auto !important;
  color: #fff !important;
}

.navbar-nav-mobile{
  display: block !important;
  font-size:2.5vh !important;
  margin-top: 8vh !important;
  font-weight: 600 !important;
}

.navbar-nav-mobile  li{
  margin-bottom: 4.4vmin !important;
}

.navbar-nav-mobile li a{
  color:#fff !important;
}

.navbar-nav-mobile li .active,
.navbar-nav-mobile  li a:hover,
.navbar-nav-mobile  li a:focus{
  color:#FFC700 !important;
}

.menu-is-open{
  overflow:hidden !important;
}

.menu-is-open .navbar-mobile{
  -webkit-transform: translateX(0) !important;
  -ms-transform: translateX(0) !important;
  -o-transform: translateX(0) !important;
  transform: translateX(0) !important;
}

.menu-is-open .click-capture{
  visibility: visible !important;
  opacity: 1 !important;
}


.navbar.fixed{
  position: fixed !important;
  z-index: 10 !important;
  top:0 !important;
  box-shadow:0 0 30px rgba(0,0,0,0.1) !important;
}

.navbar.fixed:before{
  height: 100% !important;
}

.navbar-white{
  border-bottom: 1px solid rgba(255,255,255,0.1) !important;
}

.navbar-white:not(.fixed) .phone,
.navbar-white:not(.fixed) .email,
.navbar-white:not(.fixed) a,
.navbar-white:not(.fixed) a:hover{
    color: #fff !important;
}

.navbar-white:not(.fixed) .navbar-nav-desctop li a{
  color: #fff !important;
}

.navbar-white:not(.fixed) .navbar-nav-desctop > .active > a,
.navbar-white:not(.fixed)  .navbar-nav-desctop > li > .active,
.navbar-white:not(.fixed)  .navbar-nav-desctop > li:hover > a {
    color: #23a592 !important;
}

.navbar-white:not(.fixed) .social-icons ion-icon{
  color: #fff !important;
}

.navbar-white:not(.fixed) .social-icons ion-icon:hover{
  color: #23a592 !important;
}

.navbar-white:not(.fixed) .toggler-icon{
  border-color: #fff !important;
}


.masthead{
  position: relative !important;
  min-height: 100vh !important;
  background-size: cover !important;
  display: flex !important;
  align-items: center !important;
  padding:9rem 0 5rem 0 !important;
  background-position: left top !important;
  background-size: cover !important;
}

.opener{
  position: relative !important;
  z-index: 1 !important;
  width: 100% !important;
}

.opener .modal-content{
  background-color: rgba(255,255,255,0.9) !important;
}

h1 + .line{
  margin-top:15vh !important;
  width: 25% !important;
  border-top:3px  solid #FFC700 !important;
  transition:all .3s ease !important;
}


.section{
  position: relative !important;
  padding: 9rem 0 !important;
  height: auto !important;
  display: block  !important;
}

.section-sm{
  padding: 6rem 0 !important;
}

.section h2{
  margin-top: 0 !important;
}

.pp-section{
  background: #fff !important;
  background-position: 50% !important;
  background-size: cover !important;
  padding:8rem 0 6rem !important;
}


.pp-scrollable{
  padding:0 !important;
}

.pp-scrollable .intro{
  width: 100% !important;
  height: 100% !important;
  white-space: nowrap !important;
}

.pp-scrollable .intro:before{
  content: '' !important;
  display: inline-block !important;
  vertical-align: middle !important;
}

.pp-scrollable .scroll-wrap{
  width: 100% !important;
  position: relative !important;
  display: block !important;
  vertical-align: middle !important;
  padding: 7rem 0 !important;
  white-space: normal !important;
}

.progress-nav{
  position: fixed !important;
  z-index: 10 !important;
  top: 50% !important;
  right:1.5rem !important;
  transform: translate(0,-50%) !important;
}
}

@media(min-width: 992px){
  .progress-nav{
    right: 3rem !important;
  }
}
#main_page_design {
.progress-nav li{
  position: relative !important;
  width: 3px !important;
  height:30px !important;
  -webkit-transition: background-color .3s cubic-bezier(.46,.03,.52,.96) !important;
  transition: background-color .3s cubic-bezier(.46,.03,.52,.96) !important;
  background-color: #000 !important;
}

.progress-nav li:before{
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  display: block !important;
  width: 3px !important;
  height: 0 !important;
  content: '' !important;
  transition:all .3s ease !important;
   background-color: #FFC700 !important;
}

.progress-nav li:before{
  height: 30px !important;
}

.progress-nav li.active ~ li:before {
    background-color: #101010 !important;
}

.progress-nav-white li.active ~ li:before{
  background-color: #fff !important;
}



.experience-info{
  position: absolute !important;
  left: 50% !important;
  top: 100% !important;
  transform: translateX(-50%) !important;
  margin-top: -4.5rem !important;
}

.experience-number{
  display: inline-block !important;
  font-size: 10rem !important;
  line-height: 1 !important;
  font-family: 'Montserrat', sans-serif !important;
  color:#FFC700 !important;
}

.experience-text{
  display: inline-block !important;
  color: #000 !important;
  line-height: 1.4 !important;
  font-weight: 700 !important;
}


.row-experience{
  padding:2rem 0 !important;
  border-top: 1px solid rgba(255,255,255,0.3) !important;
}

.row-experience:first-child{
  padding-top:0 !important;
  border-top:0 !important;
}

.row-experience:last-child{
  padding-bottom:0 !important;
}

.row-experience img{
  width: auto !important;
}

.download-cv{
  display: inline-block !important;
  margin-top: 3.7rem !important;
  position: relative !important;
  z-index: 1 !important;
}

.owl-dots{
  position: absolute !important;
  width: 100% !important;
  margin-top:3.5rem !important;
  text-align: center !important;
}

.owl-dot{
  display: inline-block !important;
  margin:0 0.35rem !important;
}

.owl-dot:focus{
  outline: none !important;
}

.owl-dots span{
  display: block !important;
  width: 20px !important;
  height:3px !important;
  background-color: #d6d6d6 !important;
}

.owl-dot.active span{
  background-color: #FFC700 !important;
}

.owl-nav{
  font-family: 'Montserrat', sans-serif !important;
  position: absolute !important;
  right: 0 !important;
  top: 0 !important;
  margin-top: -8.6rem !important;
  font-size: 6rem !important;
}

.owl-prev{
  margin-right: 0.6rem !important;
}

.owl-next,
.owl-prev{
  opacity: 0.45 !important;
  transition: all 0.3s !important;
}

.owl-prev:focus,
.owl-next:focus{
  outline: none !important;
}

.owl-prev:hover,
.owl-next:hover{
  opacity: 1 !important;
  color:#FFC700 !important;
}



.photo-icon{
  position: absolute !important;
  box-shadow: 0 5px 15px 5px rgba(16,16,16,.1) !important;
  overflow: hidden !important;
  transition:all .6s .4s ease !important;
  opacity: 0 !important;
}

.photo-icon-1{
  top: -30px !important;
      left: 30% !important;
      width: 115px !important;
}
.photo-icon-3{
  right: -91px !important;
      bottom: 50px !important;
}
.photo-icon-4 {
    left: 168px !important;
    bottom: 100px !important;
}
.photo-icon-5 {
    left: -40px !important;
    bottom: 100px !important;
}

.active .photo-icon-1{
  opacity: 1 !important;
      left: 100% !important;
}

.active .photo-icon-2{
  opacity: 1 !important;
  bottom: 391px !important;
  transition-delay: .7s !important;
  width: 96px !important;
}

.active .photo-icon-3{
  opacity: 1 !important;
      bottom: -4px !important;
      transition-delay: 1s !important;
      width: 118px !important;
}
.active .photo-icon-4 {
    opacity: 1 !important;
    bottom: -50px !important;
    transition-delay: .8s !important;
    width: 100px !important;
}
.active .photo-icon-5 {
  opacity: 1 !important;
      bottom: 85px !important;
      transition-delay: .4s !important;
      width: 103px !important;
}

.progress{
  height: 6px !important;
  border-radius: 0 !important;
  background-color: #000 !important;
}

.progress-bar{
  position: relative !important;
  background-color: transparent !important;
}

.progress-bar:before{
  position: absolute !important;
  background:#FFC700 !important;
  content:'' !important;
  width:0 !important;
  height: 100% !important;
}

.active .progress-bar:before{
  width: 100% !important;
  transition:all 0.8s  ease !important;
}

.progress-item:nth-child(1) .progress-bar:before{
  transition-delay: 400ms !important;
}

.progress-item:nth-child(2) .progress-bar:before{
  transition-delay: 600ms !important;
}

.progress-item:nth-child(3) .progress-bar:before{
  transition-delay: 800ms !important;
}


.project-wrap{
  width: 100% !important;
  position: relative !important;
  min-height: 100% !important;
}

.bg-changer{
  position: absolute !important;
  top:0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  overflow:hidden !important;
}

.section-bg{
  position: absolute !important;
  left: 0 !important;
  top:0 !important;
  width: 100% !important;
  height: 100% !important;
  background-size: cover !important;
  background-position: center !important;
}

.bg-changer .section-bg{
  opacity: 0 !important;
  will-change: opacity, transform !important;
  -webkit-transform: scale(1) !important;
  -ms-transform: scale(1) !important;
  -o-transform: scale(1) !important;
  transform: scale(1) !important;
  -webkit-transition: opacity 0.5s 0.2s ease,  transform 5s 0.2s ease !important;
  -o-transition: opacity 0.5s 0.2s ease,  transform 5s 0.2s ease !important;
  transition: opacity 0.5s 0.2s ease, transform 5s 0.2s ease !important;
}

.bg-changer .active{
  opacity: 1 !important;
  -webkit-transform: scale(1.05) !important;
  -ms-transform:scale(1.05) !important;
  -o-transform: scale(1.05) !important;
  transform: scale(1.05) !important;
}

.bg-changer .section-bg:first-child{
  opacity: 1 !important;
}

.row-project-box{
  margin-top: -30px !important;
}

.col-project-box{
  margin-top: 30px !important;
}

.project-box{
  display: block !important;
  padding-bottom: 30% !important;
  position: relative !important;
  border:1px solid rgba(255,255,255,0.5) !important;
  -webkit-transition: all 0.3s ease !important;
  -o-transition: all 0.3s ease !important;
  transition: all 0.3s  ease !important;
      background-size: cover !important;
}

.project-box-inner{
  position: absolute !important;
  left: 0 !important;
  top:0 !important;
  width: 100% !important;
  height: 100% !important;
  padding:2.14rem 1.928rem !important;
  background: #00000024 !important;
}
.project-box-inner:hover h4{
  text-shadow: none !important;
}
.project-box-inner:hover .project-category{
  text-shadow: none !important;
}
.project-box h4 {
    margin: 0 !important;
    color: #fff !important;
    text-shadow: 3px 2px 3px #00000030 !important;
}

.project-category{
  color: #cccccc !important;
  margin-top: 0.5rem !important;
  text-shadow: 3px 2px 3px #00000030 !important;
}

.project-box:hover{
  background: #FFC700 !important;
  text-decoration: none !important;
  border-color: #FFC700 !important;
  background-image: none  !important;
}

.project-box:hover h4{
  color: #000 !important;
}

.project-box:hover .project-category{
  color:#666666 !important;
}
}
@media (min-width: 768px){
  #main_page_design {
  .project-box {
    padding-bottom: 75% !important;
  }
  .project-category{
    position: absolute !important;
    bottom:1.2rem !important;
  }

}
}
#main_page_design {
.col-partner {
    margin: 1.5rem 0 !important;
    text-align: center !important;
}

.col-partner img{
  max-width: 100% !important;
  transition: all 0.3s ease !important;
}

.icon-quote{
  display: inline-block !important;
  margin-left: -2rem !important;
  line-height: 1 !important;
  font-size: 12rem !important;
  margin-top: -2rem !important;

}

.carousel-testimonials .owl-nav{
  position: static !important;
  margin-top: 5rem !important;
}



.message{
  margin-bottom: 2rem !important;
  display: none !important;
}


.navbar.navbar-desctop.navbar-white .logo_dark{
  display: none !important;
}
.navbar.navbar-desctop.navbar-white .logo_light{
  display: block  !important;
}
.navbar {
  z-index: 999 !important;
}
#pagepiling {
  float: left !important;
width: 100% !important;
display: block !important;
}


.pp-scrollable {
    padding: 0 !important;
}
.section {
    position: relative !important;
    height: auto !important;
    display: block  !important;
}
.text-white {
    color: #fff !important;
}
.text-white {
    color: #fff !important;
}
section.position-absolute {
    position: relative !important;
        transform: none  !important;
}
.owl-carousel {
  display: block !important;
}
html{
  width: 100vw !important;
overflow-x: hidden !important;
}

.btn_secondary {
  background: #2F80ED !important;
  color: #fff !important;
  border-color: #2F80ED !important;
  text-transform: none !important
}
p {
  color: #000 !important;
}
.creator img {
    border: 1px solid #f6f6f6 !important;
    margin-bottom: 10px !important;
    background: white !important;
}
#testimonials p, #contact p {
  color: #fff !important;
}
.col-partner img{
}
.col-partner img:hover{
}
}
html {
  scroll-behavior: smooth !important;
}
#main_page_design {
body {

    background-size: auto !important;
    background-position: center !important;
    background-attachment: fixed !important;
    background-repeat: repeat !important;
}
.news-row h4 {
  color: #000d5d !important;
}
.position-relative.creator-side .photo-icon-1 {
    width: 230px !important;
    left: 60% !important;
}
.position-relative.creator-side .photo-icon-2 {
    width: 200px !important;
    bottom: 387px !important;
}
.position-relative.creator-side .photo-icon-3 {
    width: 220px !important;
    bottom: -50px !important;
}
.devman_tm_hero .numbers.year {
  top: -20px !important;
    top:-130px !important;
  left: 0px !important;
}
.devman_tm_hero .numbers {
    position: absolute !important;
}
.devman_tm_hero .numbers .wrapper {
    padding: 15px 20px 19px !important;
    display: flex !important;
    align-items: center !important;
    background-color: #368cff !important;
    border-radius: 8px !important;
}
.devman_tm_hero .numbers .wrapper h3 {
    font-size: 45px !important;
    color: #ffc700 !important;
    line-height: 1 !important;
    position: relative !important;
    font-weight: 700 !important;
    margin: 0 !important;
}
.devman_tm_hero .numbers .wrapper .item_name {
    font-size: 19px !important;
    color: #000 !important;
    font-weight: 700 !important;
    font-family: 'Montserrat', sans-serif !important;
    line-height: 1.3 !important;
    padding-left: 14px !important;
    position: relative !important;
    top: 2px !important;
}
.devman_tm_hero .numbers .wrapper .item_name {
    font-size: 17px !important;
    line-height: 1.2 !important;
    top: 1px !important;
    padding-left: 12px !important;
}
.devman_tm_hero .numbers {
    position: absolute !important;
}

.devman_tm_hero .numbers.project .wrapper {
    padding: 13px 18px 17px !important;
        background: #ffc700 !important;
}
.devman_tm_hero .numbers.project h3 {
    color: #000b55 !important;
}
.devman_tm_hero .win {
    position: absolute !important;
    display: inline-block;
    top: 0 !important;
    right: 0 !important;
    background-color: #fff !important;
    width: 125px !important;
    height: 125px !important;
    border-radius: 12px !important;
    top: -40px !important;
    right: -40px !important;
}
.devman_tm_hero .win .svg {
    width: 65px !important;
    height: 65px !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%,-50%) !important;
}
.devman_tm_hero .win .svg {
    width: 45px !important;
    height: 45px !important;
}
.devman_tm_hero .numbers.project {
  bottom: 30px !important;
  margin-left: 0 !important;
  right: -113px !important;
  right: -133px !important;
}
.devman_tm_hero .win {
  top: -52px !important;
      right: 38px !important;
      width: 100px !important;
      height: 100px !important;
}
.banner_text {
  color: #ffffff !important;
  font-size: 13px !important;
  line-height: 19px !important;
}
.banner_text > span {
    color: #ffc700 !important;
}
.numbers.project.project_23 {
    top: 190px !important;
}
.numbers.project.project_23 .wrapper{
    background: #ffc700 !important;
    display: block !important;
    text-align: center !important;
}
.numbers.project.project_23 {
    top: 288px !important;
    right: auto !important;
    height: 110px !important;
}
.devman_tm_hero .numbers.project.project_23 .wrapper h3{
  font-size: 35px !important;
}
span.btn_item_hover {
    position: relative !important;
    display: inline-block !important;
}
span.btn_item_hover:hover ul{
  display: block !important;
}
span.btn_item_hover ul {
    position: absolute !important;
    background: #ffffff !important;
    border-radius: 7px !important;
    box-shadow: 0 0 8px 3px #0000001a !important;
    margin-top: 15px !important;
    left: -16px !important;
    display: none !important;
}
span.btn_item_hover ul::after {
    content: "" !important;
    position: absolute !important;
    top: -19px !important;
    left: 46% !important;
    border-style: solid !important;
    border-width: 10px !important;
    border-color: #f1f1f100 #0000ff00 white #ffa50000 !important;
}
span.btn_item_hover ul li:hover a{
  background: #f5f5f5 !important;
  color: #000d5d !important;
}
span.btn_item_hover ul li a {
    color: black !important;
    font-size: 13px !important;
    border-bottom: 1px solid #f3f3f3 !important;
    padding: 11px 15px !important;
    width: 170px !important;
    font-weight: bold !important;
    text-align: center !important;
    display: inline-block !important;
    border-radius: 7px !important;
}
span.btn_item_hover ul li:last-child a{
    border-bottom:none !important;
}
span.btn_item_hover ul li a:hover {
    text-decoration: none !important;
    color: black !important;
}
button.btn.btn-outline-primary {
    background: none !important;
    color: white !important;
    border-color: white !important;
}
button.btn.btn-outline-primary:hover{
  background: #fff !important;
  color: #000 !important;
}
span.btn_item_hover ul::before {
    position: absolute !important;
    top: -27px !important;
    width: 170px !important;
    height: 30px !important;
    background: #ffffff00 !important;
    content: "" !important;
    left: 0 !important;
}
.devman_tm_counter.stop {
  color: #00083b !important;
}
img.logo_dark {
    margin-top: 3px !important;
}
.header_action .btn {
  padding: 0.7em 2.4em 0.7em !important;
}
.navbar-nav >.nav-item > a.nav-link {
    font-size: 12px !important;
}
}
#main_page_design .checkbox > input:checked ~ span {
  background-color: #3699FF !important;
}
#main_page_design p.terms_text {
  line-height: 1.5rem;
}
#main_page_design  #home.pp-scrollable .scroll-wrap {
      padding: 0 !important;
}
#main_page_design a.navbar-brand.col.pl-0 {
    position: absolute;
    top: -40px;
    left: -120px;
}
#main_page_design h3.text-white.mt-0.mb-2.mt-5.banner_text_top.heading.pt-4.h2 {
    font-size: 2.5rem !important;
    text-transform: uppercase;
    font-weight: 600 !important;
        margin-top: 0 !important;
}

@media (max-width: 575.98px) {
  #main_page_design {
  .btn {
    padding: 1em 1em 1em !important;
  }
  h3 {
    font-size: 1.4rem !important;
  }
  .position-relative {
    max-width: 80vw !important;
    margin: 60px auto !important;
    margin-top: 10px !important;
  }
  .pp-scrollable .scroll-wrap {
    padding: 2rem 0 2rem !important;
  }
.active .photo-icon-2 {
  width: 97px !important;
  bottom: 267px !important;
      left: -25px !important;
}
.active .photo-icon-3 {
      width: 103px !important;
}
.photo-icon-3 {
    right: -25px !important;
  }
  .photo-icon-4{
    left: 118px !important;
  }
  .active .photo-icon-5 {
    left: 25px !important;
  }
  .active .photo-icon-1 {
    left: 60% !important;
}
.position-relative.creator-side .photo-icon-1 {
    width: 230px !important;
    left: 100px !important;
}
.position-relative.creator-side .photo-icon-2 {
  width: 160px !important;
  bottom: 117px !important;
}
.position-relative.creator-side .photo-icon-3 {
    width: 220px !important;
    bottom: -50px !important;
    right: 40px !important;
}
.devman_tm_hero .numbers .wrapper h3 {
    font-size: 25px !important;
}
.devman_tm_hero .numbers .wrapper .item_name {
    font-size: 13px !important;
}
.devman_tm_hero .numbers.project.project_23 .wrapper h3 {
    font-size: 25px !important;
}
.numbers.project.project_23 .wrapper {
  padding: 10px 15px !important;
}
.devman_tm_hero .win {
    top: -30px !important;
    right: -26px !important;
    width: 80px !important;
    height: 80px !important;
}
.devman_tm_hero .numbers.project {
    right: -24px !important;
}
.numbers.project.project_23 {
  left: -10px !important;
    right: auto !important;
    top: 150px !important;
}
.btn {
    padding: 1em 1em 1em  !important;
    font-size: 13px !important;
}
.navbar-brand.col{
  padding-right: 0  !important;
  position: static !important;
float: left !important;
}
.carousel-testimonials .owl-nav {
    margin-top: 1rem !important;
    margin-bottom: 20px !important;
}
.icon-quote {
  margin-bottom: -15px !important;
margin-left: 0 !important;
}
form.header_action {
  display: none  !important;
}
.project-box {
  min-height: 160px !important;
  background-size: cover !important;
}
.inline-block {
  display: block !important;
}
#experience .row.row-experience > .col-md-6,#experience .row.row-experience > .col-md-4.my-0{
  margin-top: 20px  !important;
}
h2, .h2 {
  font-size: 2.2rem !important;
}
.text-line.sub_text {
    font-size: 1.1rem !important;
    line-height: 21px !important;
    font-weight: 400 !important;
    margin-right: 0 !important;
}
.mob_div {
  display: none !important;
}
.devman_tm_hero  {
  margin-bottom: 0 !important;
}
 }

.banner_image {
  display: none;
}
#main_page_design h3.text-white.mt-0.mb-2.mt-5.banner_text_top.heading.pt-4.h2 {
    font-size: 2rem !important;
}
#main_page_design .position-relative.content_area_login {
  margin: 0 !important;
}
#main_page_design .banner_text {
    margin-bottom: 4rem !important;
}
#main_page_design h3.text-white.mt-0.mb-2.mt-5.banner_text_top.heading.pt-4.h2 {
    font-size: 2rem !important;
}
 }
 #main_page_design {
 .form-control {
     border-radius: 0  !important;
     border: 1px solid #f3f6f9  !important;
     background: #0000000d  !important;
     border-bottom: 1px solid #0000000a  !important;
     height: 45px  !important;
     font-weight: 500;
 }
 .container, .container-lg, .container-md, .container-sm, .container-xl {
     max-width: 1140px !important;
 }
h2.text-white {
  color: #fff !important;
}
input[name="phoneNumber"], input[name="number"] {
  padding-left: 55px !important;
}
.checkbox > span {
    background-color: #dbdbdb !important;
  }
.btn.btn_secondary:hover {
    background: #176ad9 !important;
    color: #ffffff !important;
    border-color: #176ad9 !important;
}
html {
  scroll-behavior: smooth;
}
}
.navbar.navbar.navbar-expand-md.d-flex.justify-content-between.w-100 {
    position: absolute;
    background: transparent !important;
    margin: 0 4rem;
    margin-top: 1rem;
}
#main_page_design {
  position: relative;
}
.service_item {
    background: white;
    padding: 2rem 1rem;
    white-space: normal;
    border: 1px solid #fca344;
    position: relative;
    overflow: hidden;
    margin-bottom: 25px;
}
.service_item:hover {
    box-shadow:0 0 15px 3px #0000001a;
}
#main_page_design .w-100.text-center.service_item > p.h5 {
  font-size: 1.3rem !important;
  padding: 0 0.3rem;
  margin-top: 1.5rem !important;
  margin-bottom: 0 !important;
      font-weight: 600 !important;
}
#main_page_design .w-100.text-center.service_item > p.h5.small {
  font-size: 1.1rem !important;
}
@media (max-width: 575.98px) {
  #main_page_design .active .photo-icon-5 {
    left: -17px !important;
    bottom: 85px !important;
transition-delay: .4s !important;
width: 65px !important;
}
#main_page_design .active .photo-icon-4 {
    opacity: 1 !important;
    bottom: -50px !important;
    transition-delay: .8s !important;
    width: 71px !important;
    left: 11px !important;
}
#main_page_design .active .photo-icon-1 {
    left: 87% !important;
    top: -4px !important;
left: 80% !important;
width: 69px !important;
}
#main_page_design .active .photo-icon-2 {
    width: 64px !important;
    bottom: 188px !important;
    left: 1px !important;
    display: none;
}
#main_page_design .active .photo-icon-3 {
    width: 79px !important;
    bottom: -41px !important;
}
#main_page_design h3.text-white.mt-0.mb-2.mt-5.banner_text_top.heading.pt-4.h2 {
    font-size: 2rem !important;
    margin-top: 40px !important;
}
#main_page_design .position-relative.content_area_login {
  margin: 0 !important;
}
#main_page_design .banner_text {
    margin-bottom: 4rem !important;
}
#main_page_design .position-relative.content_area_login {
    margin: 0 !important;
}
.navbar.navbar.navbar-expand-md.d-flex.justify-content-between.w-100 {
      margin: 0 1rem !important;
}
#main_page_design .w-100.text-center.service_item > p.h5 {
    font-size: 1rem !important;
    padding: 0 0.3rem;
    margin-top: 1rem !important;
}
#main_page_design .w-100.text-center.service_item {
  margin-bottom: 20px;
}
}
section#Services{
  background: rgb(255,132,64);
background: linear-gradient(115deg, rgba(255,132,64,1) 0%, rgba(250,224,77,1) 100%);

}
.w-100.text-center.service_item::after {
    position: absolute;
    top: 8px;
    left: -2rem;
    width: auto;
    height: 26px;
    background:#fff;
    color: #fff;
    transform: rotate(319deg);
    padding: 0 3rem;
    font-size: 1.1rem;
}
.w-100.text-center.service_item.new_service::after {
  content: "New";
  background: #ff1a1a;
}
.w-100.text-center.service_item.coming_soon::after {
  content: "Coming Soon";
  background: #00103d;
  top: 1.8em;
  left: -3rem;
  font-size: .9rem;
}
// .w-100.text-center.service_item.premium_card::after {
//   background: #f6dd4c;
//   color: #000 !important;
// }
.premium_card.service_item{
  background: linear-gradient(137deg, #2c79df 0%, #0a1f3c 100%);
}
.w-100.text-center.new_service.premium_card.service_item > span.premium {
  position: absolute;
      top: 0;
      right: 1px;
      width: 60px;
      height: 100%;
      background-size: 100% auto;
      background-repeat: no-repeat;
      display: block;
      padding: 15px 0;
}
#main_page_design .premium_card.service_item p.h5 {
  color: #f6dd4c !important;
}
section#partners {
    background: #eeeeee;
}
#kt_login_signin_form button.btn , #kt_login button.btn{
  padding-top: .9rem !important;
}
.fv-plugins-message-container .fv-help-block {
  font-weight: 500 !important;
}
#main_page_design .form-group.mb-0 {
    margin-bottom: 0rem !important;
}
.react-tel-input .country-list .country-name {
    margin-right: 6px;
    color: black !important;
}
