.otpContainer {
  margin: 5% auto;
}
.otpInput {
  width: 4rem !important;
  height: 4rem;
  margin: 0 1rem;
  font-size: 1.2rem;
  text-align: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  font-weight: 600;
  margin: 0;
}
.otpInput:focus {
  background: #fff;
  border: 1px solid #e3e3e3;
  outline: none;
}
.fv-plugins-message-container .fv-help-block-info {
  color: #000000;
  font-size: 0.9rem;
  font-weight: 400;
}
.required:after {
  content: " *";
  color: red;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.9rem;
  color: #f64e60;
}
.topAn {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 7px;
  text-align: flex-end;
  align-self: flex-end;
  color: #ffffff;
  top: 25px;
  right: 10px;
}
.resLogOut {
  padding: 5px 10px;
  background-color: #222;
  border-radius: 6px;
  color: #fff;
}
